import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    code: "code",
    em: "em",
    h2: "h2",
    li: "li",
    p: "p",
    pre: "pre",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: "This is a supplementary article that explains how the app state and router work in tandem."
    }), "\n", _jsx(_components.h2, {
      id: "problems-to-solve",
      children: _jsx(_components.a, {
        href: "#problems-to-solve",
        children: "Problems to Solve"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["According to ", _jsx(_components.a, {
        href: "https://frontendmastery.com/posts/the-new-wave-of-react-state-management/",
        children: "this article"
      }), " these are some problems that libraries need to solve:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Reading state from anywhere"
        }), ": covered by ", _jsx(_components.code, {
          children: "useSnapshot"
        }), " hook."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Writing to state"
        }), ": we extend this by not only writing to memory but also to disk (IndexedDB)."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Mechanisms to optimize rendering"
        }), ": Valtio snapshots handle this automatically by tracking which keys are read."]
      }), "\n", _jsxs(_components.li, {
        children: [_jsx(_components.strong, {
          children: "Optimize memory usage"
        }), ": we have to manage this manually since our state lives outside of React."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "caveats-to-writing-data",
      children: _jsx(_components.a, {
        href: "#caveats-to-writing-data",
        children: "Caveats to Writing Data"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["As a rule, we are only loading data from routes, ", _jsx(_components.em, {
        children: "not"
      }), " components. There are two ways that state gets updated:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Using a ", _jsx(_components.code, {
          children: "getData"
        }), " call from a route."]
      }), "\n", _jsxs(_components.li, {
        children: ["Calling an action that mutates ", _jsx(_components.code, {
          children: "writeState"
        }), "."]
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.em, {
        children: "The latter can cause unexpected errors if there is an unbounded number of action calls on a route."
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Consider that each route has a budget of maximum paths to write to (as of writing, ", _jsx(_components.code, {
        children: "MAX_LENGTH = 120"
      }), "). If action calls write ", _jsx(_components.em, {
        children: "multiple different paths"
      }), ", then this may trigger state cleanup to prune older paths that were required for the route component to render. However, this may not occur in practice if the number of different paths written to from actions is bounded, and does not exceed the maximum length of paths."]
    }), "\n", _jsx(_components.h2, {
      id: "routes-trigger-state-cleanup",
      children: _jsx(_components.a, {
        href: "#routes-trigger-state-cleanup",
        children: "Routes Trigger State Cleanup"
      })
    }), "\n", _jsx(_components.p, {
      children: "A consequence of only loading data from routes is that in general, routes cause data from older routes to be evicted. The layout of written paths may look like this:"
    }), "\n", _jsx(_components.pre, {
      children: _jsx(_components.code, {
        className: "language-js",
        children: "[A3, B1, B2, ..., C1, C2]\n"
      })
    }), "\n", _jsx(_components.p, {
      children: "Notice that the data for route A was truncated (missing A1, A2). Once the max paths has been reached, it starts to prune older data."
    }), "\n", _jsx(_components.h2, {
      id: "global-state-tree",
      children: _jsx(_components.a, {
        href: "#global-state-tree",
        children: "Global State Tree"
      })
    }), "\n", _jsx(_components.p, {
      children: "Our app state combines all these types of state:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "URL state: it should be assumed that the paths needed for the current route are loading, present, or Error."
      }), "\n", _jsxs(_components.li, {
        children: ["Context: instead of using React.Context there's ", _jsx(_components.code, {
          children: "state.volatile"
        }), " which has the advantage of being able to be read from anywhere outside of the component hierarchy."]
      }), "\n", _jsxs(_components.li, {
        children: ["Cache: using ", _jsx(_components.code, {
          children: "getData"
        }), " or ", _jsx(_components.code, {
          children: "readData"
        }), " handles caching automatically, so that by default, all data we fetch is cached and has an expiry time."]
      }), "\n"]
    }), "\n", _jsx(_components.h2, {
      id: "exceptions-to-the-rules",
      children: _jsx(_components.a, {
        href: "#exceptions-to-the-rules",
        children: "Exceptions to the Rules"
      })
    }), "\n", _jsx(_components.p, {
      children: "If there is functionality that spans across routes, that is if something needs to function regardless of what route a user is on, then it should not use app state at all because this whole model breaks down due to the assumption of writing data only from routes."
    }), "\n", _jsx(_components.p, {
      children: "Alternatively, it can use app state with a big caveat that it needs to be exempt from state cleanup. That means memory must be managed manually either way, given that the use case demands that feature works for the entire memory lifetime of the app."
    }), "\n", _jsx(_components.p, {
      children: "Currently the only exception so far is LoL champion select and live game."
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
